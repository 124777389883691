import { getAuthKey } from 'constant/functions'

export const pricing_plan_object = {
  plan1: {
    false: ['$', '0', '/month'],
    true: ['$', '0', '/month'],
    link: getAuthKey() === undefined ? '/signup' : '/workspace/plans',
    name: 'community_monthly',
  },
  plan2: {
    false: ['$', '31', '/month'],
    true: ['$', '17', '/month'],
    link: getAuthKey() === undefined ? '/signup' : '/workspace/plans',
    name: 'starter_monthly',
  },
  plan3: {
    false: ['$', '99', '/month'],
    true: ['$', '54', '/month'],
    link: getAuthKey() === undefined ? '/signup' : '/workspace/plans',
    name: 'growth_monthly',
  },
  plan4: {
    false: ['$', '299', '/month'],
    true: ['$', '164', '/month'],
    link: getAuthKey() === undefined ? '/signup' : '/workspace/plans',
    name: 'scale_monthly',
  },
}

export const pricing_plans = [
  {
    _id: 1,
    id: 'community_monthly',
    title: 'Community',
    price: { dollar_sign: '$', title: 'month', true: '0', false: '0' },
    yearly_charge: 'Charged now - $0/yr',
    description:
      'Start without any base fee. For every community member to get their concept into a product.',
    includes: ['No transaction fee', 'Community Support', 'Free Tradly Hosted Website'],
  },
  {
    _id: 2,
    id: 'starter_monthly',
    title: 'Starter',
    price: { dollar_sign: '$', title: 'month', true: '17', false: '31' },
    yearly_charge: 'Charged now - $204/yr',
    description:
      'For Individual & Startups validating their idea. Comes with all essential to validate with your target audience.',
    includes: [
      'No transaction fee',
      'Community + Livechat + Emails',
      'No Tradly Branding in website',
    ],
  },
  {
    _id: 3,
    id: 'growth_monthly',
    title: 'Growth',
    price: { dollar_sign: '$', title: 'month', true: '54', false: '99' },
    yearly_charge: 'Charged now - $648/yr',
    description:
      ' For proven business on their growth Stage. Have custom needs and additional integrations to grow.',
    includes: [
      'No transaction fee',
      'Slack Private Channel',
      'Custom "From Emails"',
      'Additional Mini Tools & Integrations',
    ],
  },

  {
    _id: 4,
    id: 'scale_monthly',
    title: 'Scale',
    price: { dollar_sign: '$', title: 'month', true: '164', false: '299' },
    yearly_charge: 'Charged now - $1968/yr',
    description:
      'Business who are in growth stage and looking to have be-spoke solution and other custom requirements.',
    includes: ['No transaction fee', 'Priority Support', 'Multiple Channels(App Support)', 'RBAC'],
  },
]

export const pricing_plans_with_btn_link = [
  {
    _id: 1,
    id: 'community_monthly',
    title: 'Free Community',
    fresh_work_title: 'Community',
    price: { dollar_sign: '$', title: 'month', true: '0', false: '0' },
    description:
      'Start without any base fee. For every community member to get their concept into a product.',
    link: getAuthKey() === undefined ? '/signup' : '/settings/plans',
    btn_title: '  Start Building For Free',
    includes: ['No transaction fee', 'Community Support', 'Free Tradly Hosted Website'],
  },
  {
    _id: 2,
    id: 'starter_monthly',
    title: 'Starter',
    fresh_work_title: 'Starter',
    price: { dollar_sign: '$', title: 'month', true: '17', false: '31' },
    description:
      'For Individual & Startups validating their idea. Comes with all essential to validate with your target audience.',
    link: getAuthKey() === undefined ? '/signup' : '/workspace/plans',
    btn_title: ' Start 7 Days Trial',
    includes: [
      'No transaction fee',
      'Community + Livechat + Emails',
      'No Tradly Branding in website',
    ],
  },
  {
    _id: 3,
    id: 'growth_monthly',
    title: 'Growth',
    fresh_work_title: 'Growth',
    // isEspecial: true,
    price: { dollar_sign: '$', title: 'month', true: '54', false: '99' },

    description:
      ' For proven business on their growth Stage. Have custom needs and additional integrations to grow.',
    link: getAuthKey() === undefined ? '/signup' : '/workspace/plans',
    btn_title: ' Start 7 Days Trial',
    includes: [
      'No transaction fee',
      'Slack Private Channel',
      'Custom "From Emails"',
      'Additional Mini Tools & Integrations',
    ],
  },
  {
    _id: 4,
    id: 'scale_monthly',
    title: 'Scale',
    fresh_work_title: 'Scale (previously business)',
    price: { dollar_sign: '$', title: 'month', true: '164', false: '299' },
    description:
      'Business who are in growth stage and looking to have be-spoke solution and other custom requirements.',
    includes: ['No transaction fee', 'Priority Support', 'Multiple Channels(App Support)', 'RBAC'],
  },
]
